<template>
	<router-view/>
</template>

<style>
html, body, #app, .container {
  height: 100vh; /* 确保所有父元素的高度都设置为 100% */
  width: 100vw;
  margin: 0;
  padding: 0;
  box-sizing: none;
  background-color: #F5F8FB;
   font-family: 'SimHei', 'Microsoft YaHei', sans-serif;
  

}
</style>
