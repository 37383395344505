import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home.vue'),
  	children: [
  		{
  		        path:'/home2',
  		        component: () => import("../views/home2.vue"),
  		        name: "home2",
  		        meta: { requiresAuth: true},
  		},
  		{
  		        path:'/pos',
  		        component: () => import("../views/Pos.vue"),
  		        name: "pos",
  		        meta: { requiresAuth: true},
  		},
  		{
  		        path:'/about',
  		        component: () => import("../views/About.vue"),
  		        name: "about",
  		        meta: { requiresAuth: true},
  		},
		{
		        path:'/goodsmanager',
		        component: () => import("../views/Goodsmanager.vue"),
		        name: "goodsmanager",
		        meta: { requiresAuth: true},
		},
		{
		        path:'/staff',
		        component: () => import("../views/Staff.vue"),
		        name: "staff",
		        meta: { requiresAuth: true},
		},
		{
		        path:'/vips',
		        component: () => import("../views/Vips.vue"),
		        name: "vips",
		        meta: { requiresAuth: true},
		},
		{
		        path:'/shift',
		        component: () => import("../views/Shift.vue"),
		        name: "shift",
		        meta: { requiresAuth: true},
		},
		{
		        path:'/mailreport',
		        component: () => import("../views/Mailreport.vue"),
		        name: "mailreport",
		        meta: { requiresAuth: true},
		},
		{
		        path:'/maillist',
		        component: () => import("../views/Maillist.vue"),
		        name: "maillist",
		        meta: { requiresAuth: true},
		},
  	],
  	meta: {
  	        requiresAuth: true, // 这个路由需要认证
  	      },
  },{
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// 检查 token 的函数
const checkToken = () => {
  const token = localStorage.getItem('postoken');
   console.log("路由需要认证token：",token)
  return token !== null && token !== '';
};



// 添加全局前置守卫
router.beforeEach((to, from, next) => {
  // 假设 postoken 存储在 localStorage 中
  const postoken = localStorage.getItem('postoken');
 
  // 对于登录页面，总是放行（因为用户可能正在尝试登录以获取 token）
  if (to.path === '/login') {
    return next();
  }
 
  // 检查是否存在 postoken
  if (postoken) {
    // 如果存在 postoken，则放行
    return next();
  } else {
    // 如果不存在 postoken，则重定向到登录页面
    return next({ path: '/login' });
  }
});
export default router
